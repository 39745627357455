@import 'abstracts';

$max-tablet-width: 992px;

// Disable Pull-to-Refresh feature on mobile & tablet browsers
@media screen and (max-width: $max-tablet-width) {
  html, body {
    overscroll-behavior: none;
  }
}

.page {
  &__header {
    margin-top: 35px;
    align-items: center;
  }

  &__heading {
    font-size: 22px;
    font-weight: 700;
    color: $gray-dark;
    margin: auto;
  }

  &__center {
    display: flex;
    justify-content: center;
  }

  &__actions {
    text-align: right;
    margin-top: -10px;
    @include setLeftGutters(20px);

    .dynamic-content {
      @include setLeftGutters(20px);

      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }

    .menu-action,
    .cap-button {
      margin-top: 10px;
    }
  }

  &__card {
    @include card-layout(20px);
  }

  &__content {
    margin-top: 20px;
    padding-bottom: 30px;
  }

  &__subheading {
    font-size: 14px;
    font-weight: 600;
    color: rgba($gray-dark, 0.5);
    border-bottom: 1px solid $cap-gray;
    padding: 12px 20px;
    margin-bottom: 10px;
  }

  &--dividable {
    cpb-form-renderer formly-form formly-field formly-group > formly-field {
      border-right: 1px solid $border-gray;
      padding: 15px 25px;
    }
  }

  &--except-last-child {
    cpb-form-renderer formly-form formly-field formly-group > formly-field:last-child {
      border-right: none;
    }
  }
}

.disabled-overlay {
  pointer-events: none;
  cursor: not-allowed !important; /* stylelint-disable-line declaration-no-important */
}

.header {
  .form-element .p-inputgroup input.p-inputtext {
    border: $filter-gray 2px solid;
    outline: none;
    height: 30px;
    font-size: 15px;
  }

  .p-inputgroup input.p-inputtext:hover.cap-rounded,
  .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error).cap-rounded,
  .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error).cap-rounded {
    height: 30px;
    border-width: 2px;
    font-size: 15px;
  }

  .cap-button.primary:not(:disabled) {
    background: $filter-gray;
    border-radius: 17px;
    height: 33px;
    color: $gray-dark;
    font-size: 15px;
    text-decoration: none;
    border: none;
    font-weight: 600;
  }

  cpb-list-renderer-filters .cap-button.secondary:not(:disabled) {
    background: $filter-gray;
    text-decoration: none;
  }
}

body .config-card i {
  color: var(--cap-primary-color);
  opacity: 0.5;
}

p-scroller .p-scroller {
  min-height: 40px !important; /* stylelint-disable-line declaration-no-important */
}

@include media-breakpoint-down(md) {
  .page {
    &__header {
      > div:not(.custom-header) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }

    &--dividable {
      cpb-form-renderer formly-form formly-field formly-group > formly-field {
        border-bottom: 1px solid $border-gray;
        padding: 15px 25px;
        border-right: none;
      }
    }

    &--except-last-child {
      cpb-form-renderer formly-form formly-field formly-group > formly-field:last-child {
        border-bottom: none;
      }
    }

    &__heading {
      margin-bottom: 8px;
    }

    &__center {
      display: block;
      width: 100%;
    }

    &__actions {
      .action-menu {
        top: 0;
        right: 0;
        position: fixed;
        height: 100%;
        width: 100vw;
        margin-top: 0;
        z-index: 2;
        backdrop-filter: blur(2px);
      }

      cap-button,
      cap-action-menu {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 3;
      }

      .menu-action__button-icon,
      .cap-button {
        background-color: var(--cap-primary-color) !important; /* stylelint-disable-line declaration-no-important */
        border-color: var(--cap-primary-color) !important; /* stylelint-disable-line declaration-no-important */
        height: 55px;
        width: 55px;
        border-radius: 50%;
      }

      .menu-action__items {
        bottom: 68px;
        right: 5px;
        background: transparent;
        box-shadow: none;
        width: auto;

        &:before {
          display: none;
        }

        .menu-action__info .title {
          color: $white;
          width: max-content;
          user-select: none;
        }

        li {
          border-radius: 20px;
          box-shadow: 0 2px 4px 0 rgba(2, 2, 2, 0.15);
          background-color: var(--cap-primary-color) !important; /* stylelint-disable-line declaration-no-important */
          border-color: var(--cap-primary-color) !important; /* stylelint-disable-line declaration-no-important */
          color: $white;
          margin-top: 12px;
          padding: 12px 16px;
          text-align: right;
          width: fit-content;
          float: right;
        }
      }

      .p-button-icon-left {
        color: $white;
      }

      .p-button.p-button-label-icon-left .p-button-label {
        display: none;
      }
    }
  }
}

cap-action-menu .menu-action__info .title {
  color: var(--cap-primary-color)
}

cap-sidebar-menu .cap-sidebar__button {
  i {
    color: $white;
  }

  &:hover {
    filter: brightness(0.9);
    transition: filter 0.3s ease-in-out;
  }
}

@include media-breakpoint-down(sm) {
  .page {
    &__header {
      padding-bottom: 5px;
    }

    &__content {
      margin-top: 0;
    }
  }
}

app-sales-order-list,
app-sales-order-products,
app-sales-order-product-add {
  @include media-breakpoint-down(sm) {
    .page {
      margin-top: 5px;

      &__heading {
        display: none;
      }
    }
  }
}

body {
  app-translation-list,
  app-base-data-list,
  app-user-list,
  app-role-list,
  app-tenant-list,
  app-setting-list {
    .table-filters {
      margin-left: 15px;
    }

    .cap-dynamic-filter-layout .filter-icon {
      opacity: 1 !important;
    }

    .cap-dynamic-filter-layout .cap-dynamic-filter-layout-content .p-inputgroup input::placeholder {
      opacity: 0.5 !important;
    }
  }
}

cap-login form cap-button button.cap-button.primary:not(:disabled) {
  background-color: $primary;
  border-color: $primary
}

.cap-dropdown.p-dropdown .p-dropdown-label {
  height: 34px !important;
}

@include media-breakpoint-up(md) {
  app-public-product-list app-page-layout-header .page__header,
  app-public-order-product app-page-layout-header .page__header,
  app-public-place-order app-page-layout-header .page__header {
    width: 100%;
    background-color: $light;
    z-index: 1;
    position: sticky;
    top: $nav-height !important;
    z-index: 999;
    padding: 10px $page-gutters 10px;
  }
}

.cap-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;

  .cap-progress-spinner .p-progress-spinner-circle {
    animation: cap-progress-spinner-dash 1.5s ease-in-out infinite, cap-progress-spinner-color 6s ease-in-out infinite;
  }

  @keyframes cap-progress-spinner-color {
    0% {
      stroke: $cashcarry;
    }
    50% {
      stroke: $gemotra;
    }
    80% {
      stroke: $hermes;
    }
    100% {
      stroke: $export;
    }
  }

  @keyframes cap-progress-spinner-dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
}

