@import 'variables';

@mixin setRightGutters($value) {
  & > * {
    margin-right: $value;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin setLeftGutters($value) {
  & > * {
    margin-left: $value;

    &:first-child {
      margin-left: 0;
    }
  }
}

@mixin blocktify() {
  display: block;
  width: 100%;
}

@mixin setBottomGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-bottom: $value;

    &:last-child {
      margin-bottom: $lastGutter;
    }
  }
}

@mixin setTopGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-top: $value;

    &:last-child {
      margin-top: $lastGutter;
    }
  }
}

@mixin setBottomMargin($value) {
  margin-bottom: $value;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin setLeftGutters($value) {
  & > * {
    margin-left: $value;

    &:first-child {
      margin-left: 0;
    }
  }
}

@mixin proportions($size) {
  width: $size;
  height: $size;
}

@mixin setObjectFit($type) {
  width: 100%;
  height: 100%;
  object-fit: $type;
}

@mixin flexCenteringWithSpaceBetween() {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin setAbsoluteCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  left: $size;
  transform: translate(-$size, -$size);
}

@mixin setAbsoluteVerticalCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  transform: translateY(-$size);
}

@mixin setAbsoluteHorizontalCenter() {
  $size: 50%;
  position: absolute;
  left: $size;
  transform: translateX(-$size);
}

@mixin setFontAwesomeFreeIcon($code, $weight: 900) {
  font-family: 'Font Awesome 5 Free';
  font-weight: $weight;
  content: $code;
}

@mixin setFontAwesomeProIcon($code, $weight: 900) {
  font-family: 'Font Awesome 5 Pro';
  font-weight: $weight;
  content: $code;
}

@mixin straightTop() {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

@mixin straightBottom() {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

@mixin straightRight($radius: 0) {
  border-top-right-radius: $radius;
  border-bottom-right-radius: $radius;
}

@mixin straightLeft($radius: 0) {
  border-top-left-radius: $radius;
  border-bottom-left-radius: $radius;
}

@mixin sectionDivider($vertical-margins, $color) {
  margin: $vertical-margins 0;
  padding: $vertical-margins 0;
  border-bottom: 1px solid $color;

  &:last-of-type {
    border: none;
    margin: 0;
  }
}

@mixin card-layout($padding) {
  padding: $padding;
  background-color: $white;
  border-radius: 5px;
}

@mixin flexCentering() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin setPlaceholderStyles($font-weight: 600, $font-size: 14px) {
  /* stylelint-disable */
  &:-ms-input-placeholder {
    color: $filter-font-color;
    font-weight: $font-weight;
    font-size: $font-size;
  }

  &::-webkit-input-placeholder {
    color: $filter-font-color;
    font-weight: $font-weight;
    font-size: $font-size;
  }

  &::placeholder {
    color: $filter-font-color;
    font-weight: $font-weight;
    font-size: $font-size;
  }
}

@mixin creditnoteDialog() {
  &__header {
    @include flexCenteringWithSpaceBetween;
    padding: 5px 0 15px;

    span {
      font-size: 16px;
      font-family: $font-semi-bold;
      height: 22px;
      width: 73px;
      color: $column-text-color;
    }
  }

  &__buttons {
    @include flexCenteringWithSpaceBetween();
    padding: 30px 0;
    margin-left: 10px;
    border-top: 1px solid $cap-gray;

    .action-buttons cap-button:first-child {
      margin-right: 20px;
    }
  }
}

@mixin creditnoteList() {
  max-width: 800px;
  overflow-x: hidden;
  overflow-y: auto;
  margin-bottom: 20px;
  height: fit-content;
  max-height: 400px;
}

@mixin textEllipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin footerButtons {
  @media screen and (max-width: 767.98px) {
    .footer-buttons {
      position: fixed;
      bottom: 0;
      width: 100vw;
      background-color: $white;
      margin-left: -25px;
      padding: 20px 20px 60px !important;
      box-shadow: -1px -6px 10px 0 rgba(0, 0, 0, 0.1);

      cap-button {
        flex: 1;

        .p-button.cap-button {
          display: flex;
          justify-content: center;
          padding: 25px 20px;
          font-size: 1.6rem;
          width: 100%;
        }

        .p-button-label {
          flex: none;
          font-weight: normal;
        }
      }

      .buttons__submit {
        display: flex;
        align-items: center;
      }
    }
  }

  .footer-buttons {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 1px solid $border-color;
  }
}

@mixin tabletHeader {
  @media screen and (max-width: 767.98px) {
    p-dynamicdialog {
      .full-height-mobile {
         .p-dialog-header {
          height: 120px !important;

           .p-dialog-title {
             margin-top: 30px;
           }

           .p-dialog-header-icons {
             margin-top: 30px;
           }
        }
      }
    }
  }
}

@mixin orderTotal() {
  .panel {
    width: 100%;
    display: flex;
    flex-direction: row;

    &__side {
      width: 33%;
      padding: 15px 25px;

      @include media-breakpoint-down(lg) {
        width: 50%;
      }
    }

    &__side {
      border-right: 1px solid $border-color;
      padding-right: 20px;
    }

    &__side:last-child {
      border-right: none;
      padding-right: 0;
    }
  }

  .item {
    @include flexCenteringWithSpaceBetween;
    margin: 10px 0;
    flex-wrap: wrap;

    &__label {
      @include textEllipsis();
      color: $liver;
      font-size: 16px;
      font-weight: 600;
      line-height: 18px;
      max-width: 300px;
      width: 150px;
      flex: 1 2 33%;
      padding-right: 15px;
    }

    &__value {
      font-size: 15px;
      color: $liver;
      flex: 1 2 66%;
    }
  }

  @include media-breakpoint-down(md) {
    .panel {
      display: flex;
      flex-direction: column;

      &__side {
        width: 100%;
      }

      &__side:first-child {
        border-right: none;
        border-bottom: 1px solid $border-color;
        padding-right: 20px;
      }
    }

    .item__label,
    .item__value {
      font-size: 15px;
      font-weight: 600;
      flex: 1 1 50%;
    }
  }
}

@mixin mobileHeader() {
  @include media-breakpoint-down(md) {
    .basic-layout {
      .user-info,
      .basic-layout__sidebar {
        display: none;
      }

      .menu-cta {
        border: 0;
        box-shadow: none;
        font-size: 18px;
        width: auto;
        padding: 15px;
        margin-right: -15px;

        i:before {
          color: $gray-dark;
          @include setFontAwesomeProIcon('\f0c9');
        }
      }
    }
  }
}

@mixin showOnlyColumnsMobile($haveActions, $items...) {
  .actions-on-mobile {
    width: 30px;
    padding: 0;
    padding-right: 20px;
    display: table-cell;
  }

  .cap-info-table.p-datatable {
    .p-datatable-thead th {
      display: none;

      @each $item in $items {
        &:nth-child(#{$item }) {
          display: table-cell !important; /* stylelint-disable-line declaration-no-important */
        }
      }

      @if not $haveActions {
        &:last-child {
          @extend .actions-on-mobile;
        }
      }
    }

    .p-datatable-tbody td:not(.frozen-actions):not(.customer_price) {
      display: none;
      @each $item in $items {
        // + 1 because o have some one cell for actions on mobile

        @if $haveActions {
          &:nth-child(#{$item}) {
            display: table-cell !important; /* stylelint-disable-line declaration-no-important */
          }
        } @else {
          &:nth-child(#{$item + 1}) {
            display: table-cell !important; /* stylelint-disable-line declaration-no-important */
          }
        }
      }

      @if not $haveActions {
        &:last-child {
          @extend .actions-on-mobile;
        }
      }
    }
  }
}

@mixin dialogButtonsMobile() {
  padding: 0;
  display: block;

  .p-button.cap-button {
    width: 100%;
    margin-top: 10px;
  }
}
