@import 'abstracts';

body {
  app-page-layout-header {
    p-splitbutton {
      button {
        background-color: var(--cap-primary-color);
        outline: none;
        border: none;
        border-radius: 3px;
        height: 40px;
        padding: 0.2rem 5px 0.2rem 20px;

        span {
          color: $white;
          font-size: 14px;
          font-family: $font-bold;
        }
      }

      p-tieredmenu div.p-tieredmenu-overlay.p-tieredmenu.p-component {
        width: 100% !important; /* stylelint-disable-line declaration-no-important */

        .p-menuitem-link {
          background-color: $white;
          border-bottom: 1px solid $cap-input-group-addon-border-color;
          cursor: pointer;
          color: var(--cap-primary-color);

          .p-menuitem-text {
            font-family: $font-bold;
            font-size: 12px;
          }
        }

        .p-menuitem-link:hover {
          background-color: $cap-platinum;
        }
      }
    }

    p-splitbutton button.p-button-icon-only {
      padding: 5px 20px 5px 5px;
    }
  }
}
