@import 'abstracts';

.form-group.position-before {
  align-items: center;

  .readonly-container {
    min-height: auto;
  }
}

body cpb-form-renderer {
  cpb-label-input {
    label {
      margin: 0;
    }
  }

  .form-group,
  .cbp-default-input-wrapper.form-group.position-before {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 5px 0;
    flex-wrap: wrap;

    .label-wrapper,
    .position-before .label-wrapper
    .field-wrapper {
      margin-bottom: 5px;
      min-width: 100px;
    }

    .label-wrapper {
      flex: 1 2 33%;
      max-width: unset;
      padding-right: 15px;

      @include media-breakpoint-up(md) {
        margin-top: 5px;
      }

      @include media-breakpoint-down(sm) {
        margin-top: 7px;
      }

      label {
        @include textEllipsis();
        color: $liver;
        font-size: 16px;
      }
    }

    .field-wrapper {
      flex: 2 2 66%;
      max-width: 66%;

      cpb-readonly-container .readonly-container {
        word-wrap: break-word;
        word-break: break-all;

        @include media-breakpoint-up(md) {
          margin-top: 12px;
        }
      }
    }
  }
}

app-customer-product-form {
  cap-validator {
    display: none;
  }
}

@include media-breakpoint-down(sm) {
  cpb-form-renderer {
    .hide-on-mobile {
      display: none;
    }
  }
}

.cap-dropdown.p-dropdown .p-dropdown-trigger,
.cap-dropdown.p-dropdown .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover .p-multiselect-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-dropdown-trigger,
.cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus .p-multiselect-trigger,
.cap-multi-select.p-multiselect .p-dropdown-trigger,
.cap-multi-select.p-multiselect .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover .p-multiselect-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-dropdown-trigger,
.cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus .p-multiselect-trigger {
  border: none;
}

body .p-inputgroup input.p-inputtext,
body .p-inputgroup input.p-inputtext:hover,
body .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error),
body .p-inputgroup .p-inputtext:enabled:focus:not(.p-state-error) {
  border: 1px solid $input-border-color;
  border-radius: 3px;
  width: inherit;

  @include media-breakpoint-down(sm) {
    font-size: 0.75rem;
  }
}

body .p-inputtext, .cap-textarea {
  @include media-breakpoint-down(sm) {
    font-size: 0.75rem;
  }
}

body {
  .cap-dropdown.p-dropdown,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled):hover,
  .cap-dropdown.p-dropdown.p-dropdown:not(.p-disabled).p-focus,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled):hover,
  .cap-dropdown.p-dropdown.p-multiselect:not(.p-disabled).p-focus,
  .cap-multi-select.p-multiselect,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled):hover,
  .cap-multi-select.p-multiselect.p-dropdown:not(.p-disabled).p-focus,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled):hover,
  .cap-multi-select.p-multiselect.p-multiselect:not(.p-disabled).p-focus {
    border: 1px solid $input-border-color;
    border-radius: 3px;
  }

  cap-dropdown {
    p-dropdown.p-inputwrapper-focus {
      .cap-dropdown.p-dropdown {
        border: 2px solid $focused-input-border !important;
      }
    }
  }
}

cpb-radio-input {
  form {
    @include setBottomGutters(10px);

    .p-radiobutton {
      margin-right: 10px;
    }
  }
}

body .form-group cpb-readonly-container .readonly-container {
  font-size: 15px;
  min-height: auto;
  color: $liver;
  margin-top: 7px;

  @include media-breakpoint-down(sm) {
    margin-top: 12px;
  }
}

cpb-checkbox-wrapper,
cpb-checkbox-wrapper .form-group,
cpb-checkbox-wrapper .form-group .readonly-container,
cpb-checkbox-wrapper.form-group.readonly-container .label-wrapper {
  display: flex;
  flex: 1;
}

cpb-checkbox-wrapper .readonly-container .label-wrapper {
  align-items: baseline;
  flex-flow: row-reverse wrap;

  cpb-checkbox-input {
    flex: 2;
    min-width: 125px;
  }

  label {
    flex: 1;
    min-width: 150px;
    margin-right: 5px;
  }
}

cpb-checkbox-wrapper .form-group .checkbox-wrapper-container .label-wrapper {
  display: flex;
  flex-flow: row-reverse wrap;
  flex: 1 2 33%;
  padding-right: 15px;

  cpb-checkbox-input {
    flex: 2;
    min-width: 150px;
  }

  .checkbox-label {
    @include textEllipsis();
    flex: 1;
    min-width: 85px;
    margin-left: unset;
    margin-right: 5px;
  }
}

app-supplier-form, app-supplier-view {
  .checkbox-wrapper.form-group.position-before .checkbox-wrapper-container .label-wrapper {
    overflow: hidden;
    flex: 1 2 33% !important; /* stylelint-disable-line declaration-no-important */
    max-width: unset;
  }

  .checkbox-wrapper.form-group.position-before .checkbox-wrapper-container .field-wrapper {
    flex: 2 2 66% !important; /* stylelint-disable-line declaration-no-important */
  }

  cpb-checkbox-wrapper .form-group .checkbox-wrapper-container .label-wrapper .checkbox-label {
    min-width: unset;
  }
}

app-sales-order-view {
  @include media-breakpoint-down(sm) {
    cpb-checkbox-wrapper .form-group.readonly-container,
    .checkbox-wrapper-container {
      display: flex;

      .label-wrapper {
        cpb-checkbox-input,
        .checkbox-label {
          min-width: 80px !important; /* stylelint-disable-line declaration-no-important */
        }
      }

      &:not(.readonly-container) {
        .field-wrapper {
          margin-top: 7px;
        }
      }
    }

    cpb-default-input-wrapper .cbp-default-input-wrapper.cpb-readonly cpb-number-format-input .p-inputgroup {
      margin-top: 12px;
    }
  }
}

.checkbox-wrapper-container {
  flex: 1;
}

.form-header label {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  color: $liver;
}

@media only screen and (max-width: 1400px) {
  cpb-checkbox-wrapper .form-group .checkbox-wrapper-container .label-wrapper {
    padding-right: 10px !important;
  }
}

.custom-forms cpb-checkbox-wrapper .form-group .checkbox-wrapper-container .label-wrapper {
  flex-direction: row-reverse;

  @media only screen and (max-width: 362px) {
    flex-direction: column-reverse;
  }
}

cpb-translated-input {
  .translated-field-group .field-group-input .repeat-field-row formly-field,
  .translated-field-group .field-group-input .repeat-field-row formly-field .form-group,
  .translated-field-group .field-group-input .repeat-field-row formly-field .form-group input {
    height: 36px;
    padding: 0;
    margin: 0;
    background-color: $white;
  }

  .translated-field-group .field-group-input .repeat-field-row formly-field .form-group .field-wrapper {
    margin: auto 5px;
  }

  .translated-field-group .field-group-input .repeat-field-row formly-field .form-group input {
    padding-left: 11px;
  }
}

body .cbp-default-input-wrapper .form-invalid-feedback {
  min-height: 0;
}

body .translated-field-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .field-locale-selector {
    position: relative;
    right: 0;
    top: 0;
    transform: none;
  }

  .translated-field-group {
    width: 100%;
  }
}

cap-input-number,
cap-input-number p-inputnumber,
cap-input-number p-inputnumber span,
cap-input-number p-inputnumber span input {
  width: 100%;
}

@include media-breakpoint-down(md) {
  body {
    formly-group.cpb-grid > formly-field {
      width: 100%;

      .form-group .label-wrapper label {
        margin: 0;
      }
    }

    cpb-checkbox-wrapper .form-group .checkbox-wrapper-container .label-wrapper {
      flex-direction: row-reverse;

      cpb-checkbox-input,
      .checkbox-label {
        min-width: 100px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    body {
      .form-header label {
        font-size: 16px;
        margin: 10px 0 0;
      }

      .cap-number-format-readonly .p-inputnumber-input.p-component:disabled {
        font-size: 15px;
      }

      .form-group cpb-readonly-container .readonly-container {
        font-size: 15px;
      }

      cpb-form-renderer formly-group.cpb-grid > formly-field .form-group .label-wrapper label {
        font-size: 15px;
      }
    }
  }
}

body {
  app-spot-form,
  app-location-form {
    cpb-form-renderer {
      .form-group,
      .cbp-default-input-wrapper.form-group.position-before {
        display: flex;
        justify-content: inherit !important; /* stylelint-disable-line declaration-no-important */

        .label-wrapper {
          flex: 0 2 15%;
        }

        .field-wrapper {
          flex: 0 2 30%;
          max-width: 30%;
        }
      }
    }
  }
}

app-entity-form-dialog {
  .cap-number-format-readonly .p-inputnumber-input.p-component:disabled {
    padding-left: 11px !important; /* stylelint-disable-line declaration-no-important */
  }
}
