@import '~bootstrap/scss/bootstrap-grid';

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1500px;
  }
}

// add additional class to current classes from bootstrap
// https://getbootstrap.com/docs/4.0/utilities/text/
.font-weight-semi-bold {
  font-weight: 600;
}
