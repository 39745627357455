@import 'abstracts';

@include tabletHeader;

p-dynamicdialog {
  app-multiple-rides-dialog {
    .p-calendar {
      .p-datepicker {
        position: absolute !important;
        top: 38px !important;
        width: 300px;
      }
    }
  }

  .multiple-ride {
      .p-dialog-content {
        overflow-y: visible;
      }
  }

  .split-product {
    .cap-number-format-readonly .p-inputnumber-input.p-component:disabled {
      height: 38px !important;
    }
  }

  .row-move-product {
    @include media-breakpoint-down(sm) {
      .cap-number-format-readonly .p-inputnumber-input.p-component:disabled {
        height: 38px !important;
        font-size: 1.25rem;
      }

      cpb-checkbox-wrapper .form-group .checkbox-wrapper-container .label-wrapper .checkbox-label,
      .cbp-default-input-wrapper.form-group.position-before .label-wrapper label,
      .cap-dropdown.p-dropdown .p-dropdown-label,
      .custom-forms .form-group cpb-readonly-container div.readonly-container,
      .p-inputgroup input.p-inputtext,
      cap-dropdown-panel.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
        font-size: 1.25rem;
      }

      .cbp-default-input-wrapper.form-group.position-before .field-wrapper {
        margin-top: 5px;
      }

      .checkbox-wrapper.form-group.position-before .checkbox-wrapper-container .label-wrapper {
        flex: 0 1 34%;
        max-width: 34%;
      }

      .p-checkbox-box {
        width: 25px;
        height: 25px;
      }

      .p-dropdown-clear-icon,
      .p-dropdown-trigger-icon {
        font-size: 1rem !important;
      }

      .p-dropdown-clear-icon {
        margin-top: 0;
        top: 30%;
        left: 90%;
      }

      .buttons__submit {
        display: flex;
        align-items: center;
      }

      cap-button {
        .p-button.cap-button {
          display: flex;
          justify-content: center;
          padding: 25px 20px;
        }

        .p-button-label {
          flex: none;
          font-size: 1.6rem;
          font-weight: normal;
        }
      }
    }
  }

  .order-picking-report-change,
  .split-product,
  .add-spot-product {
    @include media-breakpoint-down(md) {
      cpb-form-renderer .form-group .label-wrapper label,
      .form-group cpb-readonly-container .readonly-container,
      .p-inputgroup input.p-inputtext, body .p-inputgroup input.p-inputtext:hover, body .p-inputgroup .p-inputtext:enabled:hover:not(.p-state-error) {
        font-size: 1.3rem !important;
      }
    }
  }

  .p-dialog {
    min-width: 800px;
    max-width: 800px;

    &.batch-invoice {
      min-width: 400px;
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
      min-width: auto;
    }

    .p-dialog-header {
      padding-right: 45px;
      padding-left: 45px;
      height: 80px;
      background: var(--cap-primary-color);

      .p-dialog-title {
        font-size: 22px;
        font-weight: bold;
        color: $white;
      }

      .p-dialog-header-close-icon {
        color: $white;
        font-weight: bold;
      }
    }

    .p-dialog-content {
      padding: 24px 45px;

      @include media-breakpoint-down(md) {
        padding: 24px;
      }
    }

    .dialog-content__buttons {
      @include media-breakpoint-down(xs) {
        @include dialogButtonsMobile();
      }
    }

    .pro-forma-invoice {
      .dialog-content__buttons {
        @media (max-width: 650px) {
          @include dialogButtonsMobile();
        }
      }
    }

    &.check-products-dialog, &.allocate-products-dialog {
      min-width: 1000px;
      max-width: 1000px;

      @include media-breakpoint-down(sm) {
        width: 100%;
        min-width: auto;
      }
    }

    &.allocate-products-dialog {
      .p-dialog-content {
        overflow-y: visible;
      }
    }

    &.submit-order-picking {
      .p-dialog-content {
        background-color: $gray-light;
      }
    }
  }
  .p-calendar .p-datepicker {
    display: block !important; /* stylelint-disable-line declaration-no-important */
    position: fixed;
    top: 235px !important; /* stylelint-disable-line declaration-no-important */
    left: auto !important; /* stylelint-disable-line declaration-no-important */

    @include media-breakpoint-down(xl) {
      min-width: 150px !important; /* stylelint-disable-line declaration-no-important */
    }

    @include media-breakpoint-down(md) {
      width: 61%;
    }
  }

  app-entity-form-dialog {
    cpb-form-renderer {
      cpb-checkbox-wrapper {
        .checkbox-wrapper-container {
          display: flex;

          .field-wrapper {
            align-self: center;
          }
        }
      }
    }
  }

  cpb-list-renderer-filters-dialog {
    .cap-rounded.cap-multi-select.p-multiselect,
    .cap-rounded.cap-dropdown.p-dropdown,
    .cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled).p-focus,
    .cap-rounded.cap-multi-select.p-multiselect:not(.p-disabled):hover,
    .cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled):hover {
      border-radius: 20px;
    }

    .cap-dropdown.p-dropdown .p-dropdown-label {
      height: 29px !important;
    }
  }
}

body .p-dialog.list-renderer-filters-dialog {
  .cap-rounded.cap-multi-select.p-multiselect,
  .cap-rounded.cap-dropdown.p-dropdown,
  .cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled).p-focus,
  .cap-rounded.cap-multi-select.p-multiselect:not(.p-disabled):hover,
  .cap-rounded.cap-dropdown.p-dropdown:not(.p-disabled):hover {
    border-color: $border-color;
  }

  .dialog-footer .primary .p-button-label {
    color: $white;
  }

  .p-dialog-header {
    background-color: $white;
    height: 70px;
  }

  .p-dialog-title,
  .p-dialog-header-close-icon {
    color: $gray-dark;
    font-size: 16px;
  }

  .table-filters-items .cap-calendar.p-calendar.cap-rounded input {
    border: 1px solid var(--cap-primary-color);

    .p-inputtext {
      font-weight: normal;
      color: $column-text-color;
    }
  }
}

cpb-list-renderer-filters .actions.handset.global-search-enabled .cap-button {
  background-color: var(--cap-primary-color);

  .fa-filter.p-button-icon {
    color: $white;
  }
}

.buttons {
  display: flex;
  padding: 30px 0 6px;
  align-items: center;
  margin-left: 10px;
  justify-content: space-between;
  border-top: 1px solid $cap-gray;
  position: sticky;
  flex-wrap: wrap;

  &__submit {
    flex-wrap: nowrap;

    cap-button:first-child {
      margin-right: 5px;
    }
  }

  @include media-breakpoint-down(sm) {
    .buttons__submit {
      width: 100%;
    }
    cap-button,
    cap-button button {
      width: 100%;
      margin: 5px 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .full-height-mobile {
    max-height: 100vh;
    min-height: 100vh;

    .p-dialog-header {
      background-color: $white !important;

      span {
        color: $column-text-color !important;
      }
    }

    .p-dialog-content {
      height: calc(100vh - 80px);

      app-entity-form-dialog {
        height: inherit;

        .custom-forms {
          height: calc(100vh - 170px);
          display: flex;
          flex-direction: column;

          &__wrapper {
            max-height: unset;
            overflow: hidden;
            flex: 1;
          }

          &__buttons.buttons {
            padding: 0 0 30px 0;
            margin-left: 0;

            cap-button,
            .buttons__submit {
              flex: 1;
            }

            .buttons__submit {
              margin-left: 10px;

              button {
                background-color: var(--cap-primary-color);
                border-color: var(--cap-primary-color);
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767.98px) {
  .custom-forms__buttons {
    cap-button {
      flex: 1;

      .p-button.cap-button {
        display: flex;
        justify-content: center;
        padding: 25px 20px;
        font-size: 1.6rem;
        width: 100%;
      }

      .p-button-label {
        flex: none;
        font-weight: normal;
      }
    }

    .buttons__submit {
      display: flex;
      align-items: center;
    }
  }
}

@include media-breakpoint-down(sm) {
  body .cap-dropdown-panel.p-dropdown-panel {
    .p-scroller {
      min-height: 200px !important;
    }

    .p-dropdown-items {
      .p-dropdown-item,
      .p-dropdown-empty-message {
        font-size: 1.25rem !important;
      }

      .p-dropdown-empty-message {
        padding-top: 10px;
        height: 55px;
      }
    }
  }
}

@media screen and (max-width: 767.98px) {
  .p-inputgroup input.p-inputtext,
  .p-inputtext {
    font-size: 1.1rem !important;
  }
}

